import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceContact {
    lookupService = new APIServiceLookUp();

    async getAllContacts(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}contact/getAllContacts`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateContact(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}contact/updateContact`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}