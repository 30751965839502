import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { authAtom, userAtom } from '../../config/atoms';

import { LayoutDashboard, SquareUser, Inbox, Settings, LogOut } from 'lucide-react';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import icLogo from '../../../assets/image/logo-icon.png';

export default function NavigationBar() {

    const { page } = useParams();

    const [authStatus, setAuthStatus] = useRecoilState(authAtom);
    const [userData, setUserData] = useRecoilState(userAtom);

    const navigate = useNavigate();

    const changeMainMenu = (link) => {
        navigate(`/home/${link}`);
    }

    const getAgent = () => {
        if (page === 'agent' || page === 'agentAdd' || page == 'agentEdit') {
            return true;
        }
        return false;
    }

    const logout = () => {
        setAuthStatus('default');
        setUserData({});
        navigate('/');
    }

    return (
        <>
            {authStatus === 'default' && <Navigate to="/" />}
            <div className="h-screen shadow-md bg-prime hidden xs:block">
                <span className="cursor-pointer" onClick={() => { changeMainMenu('main') }}>
                    <div className="w-16 h-16">
                        <img src={icLogo} className="w-12 m-auto py-2 brdImg" />
                    </div>
                </span>
                <div className='w-3/5 h-[2px] bg-white rounded-full mx-[20%] my-1'></div>
                <ul className="flex flex-col items-center h-[90%]">
                    <Tippy content="Overview Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === "main" ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('main') }}>
                            <LayoutDashboard size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Requests Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === "requests" ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('requests') }}>
                            <Inbox size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Contact Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === "contact" ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('contact') }}>
                            <Inbox size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Agent Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${getAgent() ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('agent') }}>
                            <SquareUser size={20} />
                        </li>
                    </Tippy>
                    <div className='flex-grow'></div>
                    <Tippy content="Settings" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === "setting" ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('setting') }}>
                            <Settings size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Logout" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white text-white hover:text-ascent`} onClick={() => { logout() }}>
                            <LogOut size={20} />
                        </li>
                    </Tippy>
                </ul>
            </div>
        </>
    )
}
