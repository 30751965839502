import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import NavigationBar from '../core/widgets/ui/NavigationBar';

import LayoutHome from '../layouts/LayoutHome';
import LayoutRequests from '../layouts/LayoutRequests';
import LayoutContact from '../layouts/LayoutContact';
import LayoutAgent from '../layouts/LayoutAgent';
import LayoutSetting from '../layouts/LayoutSetting';

export default function PageHome() {

    const { page } = useParams();

    return (
        <div className='w-full h-screen overflow-hidden flex'>
            <NavigationBar />
            {(() => {
                switch (page) {
                    case 'main':
                        return (<LayoutHome />);
                    case 'requests':
                        return (<LayoutRequests />);
                    case 'contact':
                        return (<LayoutContact />);
                    case 'agent':
                    case 'agentAdd':
                    case 'agentEdit':
                        return (<LayoutAgent />);
                    case 'setting':
                        return (<LayoutSetting />);
                    default:
                        return (
                            <div className="w-full h-full text-5xl flex items-center justify-center" style={{ height: "80vh" }}>
                                No Data Found
                            </div>
                        );
                }
            })()}
        </div>
    )
}
