import React from 'react';
import { useParams } from 'react-router-dom';

import PanelDev from '../panels/dev/PanelDev';

import PanelContactList from '../panels/contact/PanelContactList';

export default function LayoutContact() {

    const { page } = useParams();

    return (
        <>
            <div className='h-screen w-full'>
                {
                    (() => {
                        switch (page) {
                            case 'contact':
                                return (<PanelContactList />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    )
}
