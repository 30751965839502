import React from 'react';
import { useParams } from 'react-router-dom';

import PanelAgentList from '../panels/agent/PanelAgentList';
import PanelAgentAdd from '../panels/agent/PanelAgentAdd';
// import PanelStaffEdit from '../panels/agent/PanelStaffEdit';

import PanelDev from '../panels/dev/PanelDev';

export default function LayoutAgent() {

    const { page } = useParams();

    return (
        <div className='h-screen w-full'>
            {
                (() => {
                    switch (page) {
                        case 'agent':
                            return (<PanelAgentList />);
                        case 'agentAdd':
                            return (<PanelAgentAdd />);
                        // case 'agentEdit':
                        //     return (<PanelAgentEdit />);
                        default:
                            return (<PanelDev />);
                    }
                })()
            }
        </div>
    )
}