import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceOverview {
    lookupService = new APIServiceLookUp();

    async getOrderCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}overiview/getOrderCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getSalesCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}overiview/getSalesCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getNewQueries(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}overiview/getNewQueries`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}