import React, { useState, useEffect } from 'react';

import Lottie from 'lottie-react';

import animPage from '../../assets/anim/anim-pageload.json';

import TopBar from '../../core/widgets/ui/TopBar';

import ComponentQueryCountCard from './components/card/ComponentQueryCountCard';
import ComponentOrderCountCard from './components/card/ComponentOrderCountCard';

import ComponentNewOrderList from './components/list/ComponentNewOrderList';
import ComponentNewQueryList from './components/list/ComponentNewQueryList';

export default function PanelOverview(prpos) {

    const [loading, setLoading] = useState(true);

    const loadData = () => {
        (async function () {
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-full py-3 px-3">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <div className="intro-y flex items-center justify-between h-10">
                <h2 className="text-2xl font-medium truncate ml-2">
                    Overview Dashboard
                </h2>
                <div className='z-50'>
                    <TopBar />
                </div>
            </div>
            <hr className='mt-2' />
            {
                !loading && <div>
                    <div className="grid grid-cols-4 lg:grid-cols-10 mt-6 mb-5 gap-2 xl:gap-5 ml-2 px-4">
                        <ComponentQueryCountCard />
                        <ComponentOrderCountCard />
                    </div>
                    <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2 px-4'>
                        <ComponentNewOrderList />
                    </div>
                    <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2 px-4'>
                        <ComponentNewQueryList />
                    </div>
                    <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2'></div>
                </div>
            }
        </div>
    )
}
