import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceAgent {
    lookupService = new APIServiceLookUp();

    async getAllAgent(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agent/getAllAgent`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAgentById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agent/getAgentById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateAgent(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agent/updateAgent`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateAgentById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agent/updateUser`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async deleteAgentById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agent/deleteAgentById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setAgent(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agent/setAgent`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}